import { BackendQueryKeys, useTypeSafeQuery } from '@oresundsbron/api';
import { CorporateGroupCustomer } from '@oresundsbron/validators';
import { useCustomerInformation } from '~/hooks/useCustomerInformation';

export function useCorporateGroup(): {
  isLoading: boolean;
  corporateGroup: CorporateGroupCustomer[];
  corporateHead?: CorporateGroupCustomer;
} {
  const { customerInformation } = useCustomerInformation();
  const isBusinessCustomer = customerInformation?.customerType === 'business';

  const { data, isLoading } = useTypeSafeQuery(
    BackendQueryKeys.CorporateGroupMembers(),
    (req) => req.CorporateGroup.getMembers(),
    {
      enabled: isBusinessCustomer,
    }
  );

  if (!data) {
    return { isLoading, corporateGroup: [] as CorporateGroupCustomer[] };
  }

  let { corporateGroup, corporateHead } = data;

  corporateGroup = corporateGroup
    .filter((corporate) => corporate.customerNo !== corporateHead.customerNo)
    .sort((a, b) => {
      const keyA = `${a.name}${a.companyNo}`;
      const keyB = `${b.name}${b.companyNo}`;
      return keyA.localeCompare(keyB);
    });

  return {
    corporateHead,
    corporateGroup,
    isLoading,
  };
}
