import {
  BackendError,
  BackendQueryKeys,
  useTypeSafeQuery,
} from '@oresundsbron/api';
import { ContractListItem } from '@oresundsbron/validators';
import { QueryObserverResult } from '@tanstack/react-query';
import { useCustomerInformation } from '~/hooks/useCustomerInformation';

interface UseAgreementsResult {
  contracts?: ContractListItem[];
  error: BackendError | null;
  refetch(): Promise<QueryObserverResult<ContractListItem[], BackendError>>;
}
export function usePaymentAgreementList(
  suspense = false,
  onSuccess?: (data: ContractListItem[]) => void
): UseAgreementsResult {
  const { customerInformation } = useCustomerInformation();

  const isBusinessCommuter =
    customerInformation?.customerType === 'business-commuter';

  const {
    data: contracts,
    error,
    refetch,
  } = useTypeSafeQuery(
    BackendQueryKeys.PaymentAgreementList,
    ({ Contract }) => Contract.paymentAgreementList,
    {
      suspense,
      onSuccess(data) {
        onSuccess?.(data as ContractListItem[]);
      },
      enabled: !isBusinessCommuter,
    }
  );

  return { contracts, error, refetch };
}
