import { Box, Loading } from '@oresundsbron/bridge-ui';
import { LoadingSpinner } from './LoadingSpinner';

export const LoadingScreen = () => (
  <Box
    as="main"
    className="flex items-center justify-center bg-neutral-100 p-20 sm:h-[calc(100vh_-_150px)] md:h-[calc(100vh_-_90px)]"
  >
    <LoadingSpinner />
  </Box>
);
