import { Open } from '@oresundsbron/icons';
import {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { FilterInput } from './Filter/FilterInputOption';

export interface SelectProps<T extends string> {
  label: string;
  description?: string;
  className?: string;
  placeholder?: string;
  options: FilterInput<T>[];
  onChange?: (value: T) => void;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  initialValue?: FilterInput<T>;
}

const Select = forwardRef<HTMLDivElement, SelectProps<string>>(
  <T extends string>(
    {
      label,
      description,
      options,
      className,
      placeholder,
      onChange,
      required = false,
      disabled = false,
      error,
      initialValue,
    }: SelectProps<T>,
    ref: React.Ref<HTMLDivElement> // Specify the ref type
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState<
      FilterInput<T> | undefined
    >(initialValue);

    const dropdownRef = useRef<HTMLDivElement>(null);

    // Forward the ref to dropdownRef
    useImperativeHandle(ref, () => dropdownRef.current!);

    const toggleDropdown = () => {
      if (!disabled) {
        setIsOpen(!isOpen);
      }
    };

    const handleOptionClick = (option: FilterInput<T>) => {
      if (!disabled) {
        setSelectedValue(option);
        setIsOpen(false);
        onChange?.(option.value);
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    const handleKeyDown = (
      event: React.KeyboardEvent<HTMLDivElement>,
      option: FilterInput<T>
    ) => {
      if (event.key === 'Enter' || event.key === ' ') {
        handleOptionClick(option);
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div className={`${className}`} ref={dropdownRef}>
        <label
          className={`text-neutral-950 block text-sm font-medium
            ${error ? 'text-red-500' : 'text-neutral-950'} ${
            disabled ? 'text-neutral-400' : ''
          } `}
        >
          {label}
          {required && <span> *</span>}
          {description && (
            <p
              className={`mt-2 text-sm font-normal text-neutral-800 ${
                disabled ? 'text-neutral-300' : ''
              }`}
            >
              {description}
            </p>
          )}
        </label>
        <div className="relative">
          <button
            type="button"
            tabIndex={0}
            className={`border-1 active-visible:ring focus:border-neutral-950 mt-2 flex h-[48px] w-full items-center justify-between rounded-[4px] border border-neutral-500 bg-white py-2 pl-3 focus:outline-neutral-900 focus:ring-offset-2 focus-visible:ring active:border-neutral-500 active:outline-neutral-900 active:ring-offset-2 ${
              disabled
                ? 'cursor-not-allowed border-neutral-300 bg-neutral-200'
                : 'cursor-pointer'
            } ${
              isOpen
                ? 'border-neutral-950 outline-neutral-950 border-2 ring ring-offset-2'
                : ''
            }
               `}
            onClick={toggleDropdown}
            disabled={disabled}
          >
            <div
              className={`flex items-center truncate ${
                disabled ? 'text-neutral-400' : ''
              }
                `}
            >
              {selectedValue ? selectedValue.label : placeholder}
            </div>
            <div>
              <Open
                role="button"
                className={`pointer-events-none flex h-6 w-6 items-center pr-3 align-middle ${
                  disabled ? 'text-neutral-200' : 'text-neutral-500'
                }`}
              />
            </div>
          </button>
          {isOpen && (
            <div className="border-neutral-950 absolute left-0 top-12 z-10 mt-1 max-h-60 w-full overflow-auto rounded-md border-2 bg-white">
              {options?.map((option, index) => (
                <div
                  key={index}
                  tabIndex={0}
                  role="button"
                  className={`cursor-pointer p-2 hover:bg-neutral-50 ${
                    selectedValue?.value === option.value
                      ? 'bg-neutral-100'
                      : ''
                  }`}
                  onClick={() => handleOptionClick(option)}
                  onKeyDown={(e) => handleKeyDown(e, option)}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
          {error && (
            <p className="mt-2 rounded-[4px] bg-red-50 p-2 font-normal text-red-600">
              {error}
            </p>
          )}
        </div>
      </div>
    );
  }
);

Select.displayName = 'Select';
export default Select;
