import { TFunction } from 'next-i18next';
import { ContractListItem } from '@oresundsbron/validators';
import { Issuer } from '@oresundsbron/type-safeguards';
import { constants } from '@oresundsbron/validators';

const COMMUTERS_TO_HIDE = [
  constants.OresundBusinessPendler,
  constants.OresundPendler,
];

const ACCEPTED_PROVIDERS = [Issuer.ORESUNDPAY];

export const filterCommuters = (
  data?: ContractListItem[],
  hideCommuter?: boolean
) => {
  if (!data) return [];
  if (hideCommuter) {
    return data.filter((a) => !COMMUTERS_TO_HIDE.includes(a.agreementType));
  }
  return data;
};

export const filterProviders = (
  data?: ContractListItem[],
  hideProviders?: boolean
) => {
  if (!data) return [];
  if (hideProviders) {
    return data.filter((a) =>
      ACCEPTED_PROVIDERS.includes(a.providerId as Issuer)
    );
  }
  return data;
};

export const filterAgreements = (
  data?: ContractListItem[],
  hideCommuter?: boolean,
  hideProviders?: boolean
) => {
  let filteredData = filterCommuters(data, hideCommuter);
  filteredData = filterProviders(filteredData, hideProviders);
  return filteredData;
};

export const getFilteredAgreementOptions = (
  t: TFunction,
  contracts?: ContractListItem[],
  hideCommuter?: boolean,
  hideProviders?: boolean
) => {
  const filteredContracts = filterAgreements(
    contracts,
    hideCommuter,
    hideProviders
  );
  return filteredContracts.map((agreement) => ({
    label: `${t?.(`agreementProviders.${agreement?.providerId}.label`)} - ${
      agreement.contractNo
    }`,
    value: agreement.contractNo,
  }));
};
