import { Typography } from '@oresundsbron/bridge-ui';
import { useTranslation } from 'next-i18next';
import Select, { SelectProps } from '../../../components/Inputs/Select';
import { usePaymentAgreementList } from '../Agreement/Hooks/usePaymentAgreementList';
import { getFilteredAgreementOptions } from '../Agreement/agreementSelectorUtils';
import { ContractListItem } from '@oresundsbron/validators';
import { useCallback, useEffect, useState } from 'react';

type AgreementSelectorProps = {
  onAgreementChange: (value: string) => void;
  hideCommuter?: boolean;
  hideProviders?: boolean;
  initialContractNo?: string;
} & Pick<SelectProps<string>, 'error'>;

export const AgreementSelector = ({
  onAgreementChange,
  hideCommuter,
  hideProviders,
  initialContractNo,
  ...rest
}: AgreementSelectorProps) => {
  const { t } = useTranslation(['common'], {
    nsMode: 'fallback',
  });

  const { contracts } = usePaymentAgreementList(false);

  const [previousContractNo, setPreviousContractNo] = useState<string | null>(
    null
  );

  const onContractListUpdate = useCallback(
    (data: ContractListItem[]) => {
      const filteredData = getFilteredAgreementOptions(
        t,
        data,
        hideCommuter,
        hideProviders
      );

      if (
        filteredData.length === 1 &&
        filteredData[0].value !== previousContractNo
      ) {
        onAgreementChange(filteredData[0].value);
        setPreviousContractNo(filteredData[0].value);
      }
    },
    [t, hideCommuter, hideProviders, onAgreementChange, previousContractNo]
  );

  useEffect(() => {
    if (contracts && contracts.length > 0) {
      onContractListUpdate(contracts);
    }
  }, [contracts, onContractListUpdate]);

  const agreementOptions = getFilteredAgreementOptions(
    t,
    contracts,
    hideCommuter,
    hideProviders
  );

  const initialContract = contracts?.find(
    (contract) => contract.contractNo === initialContractNo
  );
  const initialValue = initialContract
    ? {
        label: `${t(
          `agreementTypes.${initialContract?.agreementType}.label`
        )} - ${initialContract.contractNo}`,
        value: initialContract.contractNo,
      }
    : undefined;
  if (agreementOptions.length < 1) return null;

  return agreementOptions.length !== 1 ? (
    <Select
      className="sm:w-2/3"
      label={t('form.labels.contract')}
      placeholder={t('form.labels.contract')}
      options={agreementOptions}
      onChange={onAgreementChange}
      required
      initialValue={initialValue}
      {...rest}
    />
  ) : (
    <Typography className="pt-3">
      {t('form.labels.contract')}: {agreementOptions[0].label}
    </Typography>
  );
};
