import { Banner, Box } from '@oresundsbron/bridge-ui';
import { FC, PropsWithChildren, Suspense } from 'react';
import SideNavigation from '../SideNavigation';
import PageMetadata from './PageMetadata';
import ErrorBoundary from '../../ErrorBoundary';
import { Loading } from './Loading';
import { useTranslation } from 'next-i18next';
import { cx } from 'class-variance-authority';

import { AccountBreadcrumbs, ParentBreadcrumb } from './AccountBreadcrumbs';
import { useCustomerInformation } from '~/hooks/useCustomerInformation';
import { Notice } from '@oresundsbron/design-system';
import { useRouter } from 'next/router';
import { AgreementSelector } from '~/modules/account/Components/AgreementSelector';

export interface AccountContentProps {
  namespace: string;
  hideNavigation?: true;
  hideMetadata?: true;
  parentBreadcrumb?: ParentBreadcrumb[];
  queryNamespace?: boolean;
  requireSingleContract?: boolean;
}

const AccountContent: FC<PropsWithChildren<AccountContentProps>> = ({
  namespace,
  hideMetadata,
  hideNavigation,
  parentBreadcrumb,
  children,
  queryNamespace,
  requireSingleContract,
}) => {
  const { t } = useTranslation(['account', 'common'], {
    nsMode: 'fallback',
  });
  const { push, query } = useRouter();

  const { customerInformation } = useCustomerInformation();

  const setContractCallback = (contract: string) => {
    push({ query: { ...query, contract } });
  };
  const showContractSelector =
    requireSingleContract &&
    customerInformation &&
    customerInformation?.contracts.length > 1;

  return (
    <Box className="col-start-2 flex min-h-full md:gap-14">
      <Box
        className={cx(
          'w-[25%]',
          'hidden',
          !hideNavigation ? 'md:block' : undefined
        )}
      >
        <SideNavigation />
      </Box>
      <Box
        className={cx(
          'h-full w-full bg-white py-4 md:px-14 md:py-5',
          !hideNavigation ? 'md:w-[75%]' : undefined
        )}
      >
        {!hideNavigation && (
          <AccountBreadcrumbs
            queryNamespace={queryNamespace}
            namespace={namespace}
            parentBreadcrumb={parentBreadcrumb}
          />
        )}
        <PageMetadata
          namespace={namespace}
          className={hideMetadata ? 'hidden' : undefined}
          queryNamespace={queryNamespace}
        />
        {showContractSelector && (
          <>
            <div className="mb-4">
              <AgreementSelector
                hideCommuter
                onAgreementChange={setContractCallback}
                initialContractNo={query.contract as string}
              />
            </div>
          </>
        )}
        {!showContractSelector || query.contract ? (
          <ErrorBoundary
            fallback={<Banner color="negative">{t('errors.generic')}</Banner>}
          >
            <Suspense fallback={<Loading />}>{children}</Suspense>
          </ErrorBoundary>
        ) : (
          <Notice className="my-4" variant="notice">
            {t('debitCard.pickContract')}
          </Notice>
        )}
      </Box>
    </Box>
  );
};

export default AccountContent;
