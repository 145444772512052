import { FC, PropsWithChildren, useEffect } from 'react';
import { useRouter } from 'next/router';
import { LoadingScreen } from '../../LoadingScreen';
import { useIsAuthenticated } from '../../../hooks/useIsAuthenticated';
import { PageWrapper } from '@oresundsbron/bridge-ui';
import AccountContent, { AccountContentProps } from './AccountContent';
import { SEOMetadata } from '~/components/SEOMetadata';
import AccountBanner from './AccountBanner';
import { Eq as StringEq } from 'fp-ts/lib/string';
import { useTranslation } from 'next-i18next';

const AccountLayout: FC<PropsWithChildren<AccountContentProps>> = ({
  children,
  ...accountContentProps
}) => {
  const router = useRouter();
  const { t } = useTranslation('account');
  const { authenticated, loading } = useIsAuthenticated();
  useEffect(() => {
    if (!authenticated && !loading) {
      router.replace('/account/login');
    }
  }, [router, authenticated, loading]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <SEOMetadata items={[]} />
      <PageWrapper className="relative min-h-[calc(100dvh-180px)] ">
        <AccountBanner
          title={t('layout.title')}
          navigationLabel={t('navigation.dashboard')}
          dashboard={StringEq.equals(
            accountContentProps.namespace,
            'dashboard'
          )}
        />
        <AccountContent {...accountContentProps}>{children}</AccountContent>
      </PageWrapper>
    </>
  );
};
export default AccountLayout;
