import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from 'react';
import { Open } from '@oresundsbron/icons';
import {
  FilterInputOption,
  InputMode,
  FilterInput,
  showAllValue,
  ExtendedFilterInputs,
} from './FilterInputOption';
import { useTranslation } from 'next-i18next';

interface SelectInputProps<T extends string> {
  label: string;
  className?: string;
  options: FilterInput<T>[];
  onChange: Dispatch<SetStateAction<T[]>>;
  mode?: InputMode;
  showAll?: boolean;
}

const MultiSelect = <T extends string>({
  label,
  options,
  className,
  onChange,
  mode = 'default',
  showAll,
}: SelectInputProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation(['common'], {
    nsMode: 'fallback',
  });
  const showAllOption: FilterInput<typeof showAllValue> = {
    value: showAllValue,
    label: t('filter.showAll'),
  };
  const customOptions = showAll ? [showAllOption, ...options] : options;

  const [selectedOptions, setSelectedOptions] = useState<
    Array<ExtendedFilterInputs<T>>
  >(showAll ? [showAllOption] : []);

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (
    event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent,
    option: ExtendedFilterInputs<T>
  ) => {
    event.stopPropagation();

    const isAlreadySelected = selectedOptions.some(
      (selectedOption) => selectedOption.value === option.value
    );

    let newSelectedOptions;

    if (isAlreadySelected) {
      newSelectedOptions = selectedOptions.filter(
        (selectedOption) => selectedOption.value !== option.value
      );
    } else {
      if (option.value === showAllValue) {
        newSelectedOptions = [option];
      } else {
        newSelectedOptions = [
          ...selectedOptions.filter((so) => so.value !== showAllValue),
          option,
        ];
      }
    }

    if (newSelectedOptions.length === 0) {
      newSelectedOptions = showAll ? [showAllOption] : [];
    }

    setSelectedOptions(newSelectedOptions);
    onChange(
      newSelectedOptions
        .filter((o) => o.value !== showAllValue)
        .map((o) => o.value as T)
    );
  };

  const handleCheckBox = (
    event: React.KeyboardEvent,
    option: ExtendedFilterInputs<T>
  ) => {
    if (event.key === 'Enter') {
      handleOptionClick(event, option);
    }
  };

  return (
    <div className={`${className}`} ref={dropdownRef}>
      <label className="text-neutral-950 block text-sm font-medium">
        {label}
      </label>
      <div className="relative">
        <button
          tabIndex={0}
          className={`border-1 active-visible:ring focus:border-neutral-950  mt-2 flex h-[48px] w-full cursor-pointer items-center justify-between rounded-[4px] border border-neutral-500 bg-white py-2 pl-3 focus:outline-neutral-900 focus:ring-offset-2 focus-visible:ring active:border-neutral-500 active:outline-neutral-900 active:ring-offset-2 ${
            isOpen
              ? 'border-neutral-950 outline-neutral-950 border-2 ring ring-offset-2'
              : ''
          }`}
          onClick={toggleDropdown}
        >
          <div className="flex overflow-hidden">
            {selectedOptions.length > 1 ? (
              <div className="flex items-center truncate">
                <span>
                  {selectedOptions
                    .slice(0, 2)
                    .map((option) => option.label)
                    .join(', ')}
                </span>
                <span>{`...(${selectedOptions.length}st)`}</span>
              </div>
            ) : (
              selectedOptions.map((option, index) => (
                <div
                  key={option.value}
                  className={`${
                    index < selectedOptions.length - 1 ? 'mr-1' : ''
                  }`}
                >
                  {option.label}
                  {index < selectedOptions.length - 1 && ','}
                </div>
              ))
            )}
          </div>
          <div>
            <Open
              role="button"
              className="pointer-events-none flex h-6 w-6 items-center pr-3 align-middle text-neutral-500"
            />
          </div>
        </button>
        {isOpen && (
          <div className="border-neutral-950 absolute left-0 top-12 z-10 mt-1 w-full rounded-md border-2 bg-white">
            {customOptions?.map((option, index) => (
              <FilterInputOption
                key={index}
                option={option}
                handleCheckBox={handleCheckBox}
                handleOptionClick={handleOptionClick}
                selectedOptions={selectedOptions}
                mode={mode}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;
