import { ArrowUp } from '@oresundsbron/icons';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { BreadCrumbArrow } from './BreadCrumbArrow';
import { useRouter } from 'next/router';

export interface ParentBreadcrumb {
  text: string;
  url: string;
}

interface AccountBreadcrumbsProps {
  parentBreadcrumb?: ParentBreadcrumb[];
  namespace: string;
  queryNamespace?: boolean;
}

export const AccountBreadcrumbs = ({
  parentBreadcrumb,
  namespace,
  queryNamespace = false,
}: AccountBreadcrumbsProps) => {
  const { t } = useTranslation(['account', 'common', 'recognitions'], {
    nsMode: 'fallback',
  });
  const { query } = useRouter();

  return (
    <div className="flex text-sm">
      <Link href={'/account/dashboard'} className="mr-2 underline">
        {t('navigation.dashboard')}
      </Link>
      {parentBreadcrumb && (
        <>
          <div className="flex items-center">
            <ArrowUp width={8} className="mr-2 rotate-90" />
          </div>
          {parentBreadcrumb.map((breadCrumb, index) => {
            return (
              <Link
                key={index}
                className={'mr-2 underline'}
                href={{
                  pathname: breadCrumb.url,
                }}
              >
                {t(breadCrumb.text)}
              </Link>
            );
          })}
        </>
      )}
      <BreadCrumbArrow />
      <span>
        {queryNamespace ? query[namespace] : t(`${namespace}.layout.title`)}
      </span>
    </div>
  );
};
